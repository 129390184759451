import React from 'react';
import Button from '../Forms/Button';
import useForm from '../../Hooks/useForm';
import useFetch from '../../Hooks/useFetch';
import { FORGOT_PASSWORD } from '../../Api';
import Error from '../Helper/Error';
import Sucess from '../Helper/Sucess';
import Head from '../Helper/Head';
import MaskedInput from 'react-text-mask';
import styles from './LoginForm.module.css';

const EsqueceuSenha = () => {
  const cpf_cnpj = useForm();
  const { loading, error, setError, sucess, setSucess, request } = useFetch();

  async function handleSubmit(event) {
    event.preventDefault();

    if (cpf_cnpj.validate()) {
      const { url, options } = FORGOT_PASSWORD({
        cpf_cnpj: cpf_cnpj.value.replace(/\D/g, ''),
      });
      const { response, json } = await request(url, options);

      if (response === undefined) {
        return null;
      }

      if (response !== undefined) {
        if (json === null) return null;

        if (response.ok === false) {
          let errors = JSON.stringify(json.errors)
            .replace(/[\\"]/g, '')
            .replace(/[\\{]/g, '')
            .replace(/[\\}]/g, '')
            .replace(/[[\]]/g, '')
            .replace(/[\\,]/g, ', ')
            .replace(/[\\:]/g, ': ');
          setError(`Erro: ${json.message}. Motivo(s): ${errors}.`);
        }
        if (response.ok === true) {
          setSucess(`Mensagem: ${json.message}`);
        }
      }
    }
  }

  const mask = function (rawValue) {
    if (rawValue.length > 14) {
      return [
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ];
    } else {
      return [
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ];
    }
  };

  return (
    <section className="animeLeft">
      <Head title="Esqueceu a Senha?" />
      <h1 className="title">Esqueceu a Senha?</h1>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.wrapper}>
          <label htmlFor="cpf_cnpj" className={styles.label}>
            CPF ou CNPJ
          </label>
          <MaskedInput
            mask={mask}
            className={styles.input}
            guide={false}
            id="cpf_cnpj"
            name="cpf_cnpj"
            onBlur={() => {}}
            onChange={() => {}}
            required
            {...cpf_cnpj}
          />
        </div>

        {loading ? (
          <Button disabled>Enviando...</Button>
        ) : (
          <Button>Enviar</Button>
        )}

        <Error error={error} />
        <Sucess sucess={sucess} />
      </form>
    </section>
  );
};

export default EsqueceuSenha;
