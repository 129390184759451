import React from 'react';
import styles from './Footer.module.css';
import { ReactComponent as Transitar } from '../Assets/transitar.svg';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.transitarconsultoria.com/"
      >
        <Transitar />
      </a>
    </footer>
  );
};

export default Footer;
