import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ButtonRequest.module.css';

const ButtonRequest = ({ children, ...props }) => {
  return (
    <Link {...props} className={styles.link}>
      {children}
    </Link>
  );
};

export default ButtonRequest;
