import React from 'react';
import useForm from '../../Hooks/useForm';
import Button from '../Forms/Button';
import Input from '../Forms/Input';
import { UserContext } from '../../UserContext';
import styles from './LoginForm.module.css';
import Error from '../Helper/Error';
import Head from '../Helper/Head';
import MaskedInput from 'react-text-mask';
import { Link } from 'react-router-dom';

const LoginForm = () => {
  const cpf_cnpj = useForm();
  const password = useForm();

  const { userLogin, error, loading } = React.useContext(UserContext);

  async function handleSubmit(event) {
    event.preventDefault();

    if (cpf_cnpj.validate() && password.validate()) {
      let cpfCnpj = cpf_cnpj.value.replace(/\D/g, '');
      userLogin(cpfCnpj, password.value);
    }
  }

  const mask = function (rawValue) {
    if (rawValue.length > 14) {
      return [
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ];
    } else {
      return [
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ];
    }
  };

  return (
    <section className="animeLeft">
      <Head title="Login" />
      <h1 className="title">Login</h1>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.wrapper}>
          <label htmlFor="cpf_cnpj" className={styles.label}>
            CPF ou CNPJ
          </label>
          <MaskedInput
            mask={mask}
            className={styles.input}
            guide={false}
            id="cpf_cnpj"
            name="cpf_cnpj"
            onBlur={() => {}}
            onChange={() => {}}
            required
            {...cpf_cnpj}
          />
        </div>
        <Input
          className={styles.input}
          label="Senha"
          type="password"
          name="password"
          required
          {...password}
        />
        {loading ? (
          <Button disabled>Carregando...</Button>
        ) : (
          <Button>Entrar</Button>
        )}
        <Error error={error} />

        <Link className={styles.perdeu} to="forgotPassword">
          Esqueceu a Senha?
        </Link>
      </form>
    </section>
  );
};

export default LoginForm;
