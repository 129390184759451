import React from 'react';

const SistemaIndisponivel = () => {
  return (
    <div className="container mainContainer">
      <h1 className="title">Sistema Indisponível!</h1>
      <p>
        Por gentileza, entrar em contato conosco através do telefone{' '}
        <b>(85) 3223-1637</b>.
      </p>
    </div>
  );
};

export default SistemaIndisponivel;
