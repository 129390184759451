import React from 'react';
import { TOKEN_POST } from './Api';
import { useNavigate } from 'react-router-dom';

export const UserContext = React.createContext();

export const UserStorage = ({ children }) => {
  const [data, setData] = React.useState(null);
  const [login, setLogin] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const navigate = useNavigate();

  async function userLogin(cpf_cnpj, password) {
    try {
      setError(null);
      setLoading(true);
      const { url, options } = TOKEN_POST({ cpf_cnpj, password });
      const tokenRes = await fetch(url, options);
      const json = await tokenRes.json();
      if (!tokenRes.ok) {
        throw new Error(`Erro: ${json.message}`);
      }
      window.localStorage.setItem('token', json.user.token);
      window.localStorage.setItem('nome', json.user.name);
      window.localStorage.setItem('role', json.user.role);
      window.localStorage.setItem('userID', json.user._id);
      setLogin(true);
      navigate('/user');
    } catch (err) {
      if (err.message === 'Failed to fetch') {
        setError(
          'Sistema Indisponível! Por gentileza, entrar em contato conosco através do telefone (85) 3223-1637.',
        );
      } else {
        setError(err.message);
      }
      setLogin(false);
    } finally {
      setLoading(false);
    }
  }

  async function userLogout() {
    setData(null);
    setError(null);
    setLoading(false);
    setLogin(false);
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('nome');
    window.localStorage.removeItem('role');
    navigate('/login');
  }

  // PROTECTED ROUTE
  React.useEffect(() => {
    async function autoLogin() {
      const token = window.localStorage.getItem('token');
      if (token) {
        setLogin(true);
      } else {
        setLogin(false);
      }
    }
    autoLogin();
  }, [login]);

  return (
    <UserContext.Provider
      value={{ userLogin, userLogout, data, error, loading, login }}
    >
      {children}
    </UserContext.Provider>
  );
};
