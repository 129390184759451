import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ButtonRequest from '../Forms/ButtonRequest';
import Head from '../Helper/Head';
import styles from './User.module.css';
import { ReactComponent as ListarCustos } from '../../Assets/list.svg';
import { ReactComponent as ListarCustosHoje } from '../../Assets/mylist.svg';
import { ReactComponent as RegistrarCusto } from '../../Assets/compra.svg';
import { ReactComponent as EditarCusto } from '../../Assets/editar.svg';
import { ReactComponent as RegistrarUsuario } from '../../Assets/usuario.svg';

const User = () => {
  const [role, setRole] = React.useState('');

  React.useEffect(() => {
    setRole(localStorage.getItem('role'));
  }, []);

  return (
    <section className="container">
      <header className={styles.header}>
        <h1 className="title">Controle Financeiro</h1>
      </header>
      <Head title="Controle Financeiro" />
      <Routes>
        <Route exact path="/cost/list" element={<ListarCustos />} />
        <Route exact path="/cost/list/today" element={<ListarCustosHoje />} />
        <Route exact path="/cost/register" element={<RegistrarCusto />} />
        <Route exact path="/cost/edit" element={<EditarCusto />} />
        <Route exact path="/user/register" element={<RegistrarUsuario />} />
      </Routes>
      <ul className={styles.grid}>
        <li className={`${role === 'operator' && styles.disabled}`}>
          <ButtonRequest to="/cost/list">
            <ListarCustos className={styles.icon} />
            Listar Todos
            <br /> os Custos
          </ButtonRequest>
        </li>
        <li>
          <ButtonRequest to="/cost/list/today">
            <ListarCustosHoje className={styles.icon} />
            Listar Seus Registros
          </ButtonRequest>
        </li>
        <li className={`${role === 'viewer' && styles.disabled}`}>
          <ButtonRequest to="/cost/register">
            <RegistrarCusto className={styles.icon} />
            Registrar Custo
          </ButtonRequest>
        </li>
        <li
          className={`${
            (role === 'operator' || role === 'viewer') && styles.disabled
          }`}
        >
          <ButtonRequest to="/cost/edit">
            <EditarCusto className={styles.icon} />
            Editar Custo
          </ButtonRequest>
        </li>
        <li
          className={`${
            (role === 'operator' || role === 'viewer') && styles.disabled
          }`}
        >
          <ButtonRequest to="/user/register">
            <RegistrarUsuario className={styles.icon} />
            Registrar Usuário
          </ButtonRequest>
        </li>
      </ul>
    </section>
  );
};

export default User;
