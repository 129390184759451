import React from 'react';
import { REGISTRAR_CUSTO_POST } from '../../Api';
import useFetch from '../../Hooks/useFetch';
import useForm from '../../Hooks/useForm';
import Button from '../Forms/Button';
import Input from '../Forms/Input';
import Head from '../Helper/Head';
import Error from '../Helper/Error';
import SistemaIndisponivel from '../SistemaIndisponivel';
import MaskedInput from 'react-text-mask';
import styles from './Requests.module.css';
import moment from 'moment';
import CustomSelect from '../Forms/CustomSelect';
import Modal from '../Helper/Modal';

const RegistrarCusto = () => {
  const date = useForm();
  const value = useForm();
  const description = useForm();
  const [center, SetCenter] = React.useState(null);
  const [source, SetSource] = React.useState(null);
  const [operation_location, SetOperation_Location] = React.useState(null);
  const [status, setStatus] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);

  const { loading, error, setError, sucess, setSucess, request } = useFetch();

  async function handleSubmit(event) {
    event.preventDefault();

    if (description.validate() && date.validate() && value.validate()) {
      const dateBr = moment(date.value).format('MM/DD/YYYY');
      const dateConvert = new Date(dateBr);

      const body = {
        center: center,
        source: source,
        description: description.value,
        date: dateConvert.toISOString(),
        value: +value.value.replace(/\D/g, ''),
        operation_location: operation_location,
      };

      const { url, options } = REGISTRAR_CUSTO_POST(body);
      const { response, json } = await request(url, options);

      if (center !== null && source !== null && operation_location !== null) {
        setIsOpen(true);
      }

      if (response === undefined) {
        setStatus(undefined);
        return null;
      }

      if (response !== undefined) {
        if (json === null) return null;

        if (response.ok === false) {
          let errors = JSON.stringify(json.errors)
            .replace(/[\\"]/g, '')
            .replace(/[\\{]/g, '')
            .replace(/[\\}]/g, '')
            .replace(/[[\]]/g, '')
            .replace(/[\\,]/g, ', ')
            .replace(/[\\:]/g, ': ');
          setError(`Erro: ${json.message}. Motivo(s): ${errors}.`);
        }
        if (response.ok === true) {
          setSucess(`Mensagem: ${json.message}`);
          description.setValue('');
          value.setValue('');
        }
      }
    }
  }

  const maskValue = function (rawValue) {
    if (rawValue.length < 5) {
      return [/\d/, ',', /\d/, /\d/];
    } else if (rawValue.length < 6) {
      return [/\d/, /\d/, ',', /\d/, /\d/];
    } else if (rawValue.length < 7) {
      return [/\d/, /\d/, /\d/, ',', /\d/, /\d/];
    } else if (rawValue.length < 9) {
      return [/\d/, '.', /\d/, /\d/, /\d/, ',', /\d/, /\d/];
    } else if (rawValue.length < 10) {
      return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, ',', /\d/, /\d/];
    } else if (rawValue.length < 15) {
      return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, ',', /\d/, /\d/];
    }
  };

  const optionsCenter = [
    { value: '10001 - DIRETORIA', label: '10001 - DIRETORIA' },
    { value: '10002 - COMERCIAL', label: '10002 - COMERCIAL' },
    { value: '10003 - GERÊNCIAS', label: '10003 - GERÊNCIAS' },
    { value: '10004 - ADMINISTRATIVO', label: '10004 - ADMINISTRATIVO' },
    { value: '10005 - LICITAÇÃO', label: '10005 - LICITAÇÃO' },
    { value: '10006 - PLANEJAMENTO', label: '10006 - PLANEJAMENTO' },
    {
      value: '10007 - ENGENHARIA E ARQUITETURA',
      label: '10007 - ENGENHARIA E ARQUITETURA',
    },
    { value: '10008 - ESTUDOS TÉCNICOS', label: '10008 - ESTUDOS TÉCNICOS' },
    { value: '10009 - TECNOLOGIA', label: '10009 - TECNOLOGIA' },
    { value: '10010 - EXTERNO AMC', label: '10010 - EXTERNO AMC' },
    { value: '10011 - SUBCONTRATAÇÃO', label: '10011 - SUBCONTRATAÇÃO' },
    { value: '10012 - CONTABILIDADE', label: '10012 - CONTABILIDADE' },
  ];

  const optionsSource = [
    {
      value: '101 - DESPESAS COM PESSOAL',
      label: '101 - DESPESAS COM PESSOAL',
    },
    {
      value: '102 - TREINAMENTOS',
      label: '102 - TREINAMENTOS',
    },
    {
      value: '201 - TAXAS DE CONSELHOS PROFISSIONAIS',
      label: '201 - TAXAS DE CONSELHOS PROFISSIONAIS',
    },
    { value: '202 - IMPOSTOS', label: '202 - IMPOSTOS' },
    { value: '203 - ENCARGOS', label: '203 - ENCARGOS' },
    { value: '301 - CONDOMÍNIO', label: '301 - CONDOMÍNIO' },
    { value: '302 - ALUGUEL', label: '302 - ALUGUEL' },
    { value: '303 - ENERGIA', label: '303 - ENERGIA' },
    { value: '304 - COMUNICAÇÃO', label: '304 - COMUNICAÇÃO' },
    { value: '401 - TRANSPORTES', label: '401 - TRANSPORTES' },
    {
      value: '501 - MATERIAL DE USO E CONSUMO',
      label: '501 - MATERIAL DE USO E CONSUMO',
    },
    {
      value: '502 - MATERIAL DE EXPEDIENTE',
      label: '502 - MATERIAL DE EXPEDIENTE',
    },
    {
      value: '601 - MATERIAL OPERACIONAL',
      label: '601 - MATERIAL OPERACIONAL',
    },
    { value: '602 - BENS DE CONSUMO', label: '602 - BENS DE CONSUMO' },
    {
      value: '603 - BENS DE PEQUENO VALOR',
      label: '603 - BENS DE PEQUENO VALOR',
    },
    {
      value: '604 - SERV. MANUTENÇÃO E CONSERVAÇÃO',
      label: '604 - SERV. MANUTENÇÃO E CONSERVAÇÃO',
    },
    {
      value: '605 - MAT. MANUTENÇÃO E CONSERVAÇÃO',
      label: '605 - MAT. MANUTENÇÃO E CONSERVAÇÃO',
    },
    {
      value: '701 - TERCERIZAÇÃO DE SERVIÇOS ADM',
      label: '701 - TERCERIZAÇÃO DE SERVIÇOS ADM',
    },
    {
      value: '702 - TERCERIZAÇÃO DE SERVIÇOS OPE',
      label: '702 - TERCERIZAÇÃO DE SERVIÇOS OPE',
    },
    { value: '703 - PUBLICIDADE', label: '703 - PUBLICIDADE' },
    { value: '801 - ALIMENTAÇÃO', label: '801 - ALIMENTAÇÃO' },
    { value: '802 - DIÁRIAS', label: '802 - DIÁRIAS' },
    { value: '803 - HOSPEDAGEM', label: '803 - HOSPEDAGEM' },
    {
      value: '901 - TAXAS EMOLUMENTOS',
      label: '901 - TAXAS EMOLUMENTOS',
    },
    {
      value: '902 - DOAÇÕES E PATRIMÔNIOS',
      label: '902 - DOAÇÕES E PATRIMÔNIOS',
    },
    { value: '903 - OUTROS GASTOS', label: '903 - OUTROS GASTOS' },
    { value: '904 - NOVOS NEGÓCIOS', label: '904 - NOVOS NEGÓCIOS' },
    { value: '905 - INVESTIMENTOS', label: '905 - INVESTIMENTOS' },
  ];

  const optionsOperationLocation = [
    { value: '101 - BANCO INTER', label: '101 - BANCO INTER' },
    { value: '102 - BANCO BRADESCO', label: '102 - BANCO BRADESCO' },
    { value: '201 - CARTÃO DE CRÉDITO', label: '201 - CARTÃO DE CRÉDITO' },
    { value: '301 - CAIXA INTERNO', label: '301 - CAIXA INTERNO' },
  ];

  if (status === undefined) {
    return <SistemaIndisponivel />;
  } else {
    return (
      <section className="container animeLeft">
        <Head title="Registrar Custo" />
        <h1 className="title">Registrar Custo</h1>
        <form onSubmit={handleSubmit}>
          <div className={styles.flex}>
            <Input
              className={styles.input}
              label="Data de Pagamento"
              type="date"
              name="date"
              required
              {...date}
            />

            <div className={styles.wrapper}>
              <label htmlFor="value" className={styles.label}>
                Valor
              </label>
              <MaskedInput
                mask={maskValue}
                className={styles.input}
                guide={false}
                id="value"
                name="value"
                placeholder="R$"
                onBlur={() => {}}
                onChange={() => {}}
                required
                {...value}
              />
            </div>
          </div>

          <div className={styles.flex}>
            <div className={styles.wrapper}>
              <label htmlFor="Centro de Custo" className={styles.label}>
                Centro de Custo
              </label>
              <CustomSelect
                name="center"
                value={center}
                placeholder={center ? center : 'Selecione'}
                onChange={(value) => SetCenter(value.value)}
                options={optionsCenter}
                required
                {...center}
              />
            </div>

            <div className={styles.wrapper}>
              <label htmlFor="Natureza da Operação" className={styles.label}>
                Natureza da Operação
              </label>
              <CustomSelect
                name="source"
                value={source}
                placeholder={source ? source : 'Selecione'}
                onChange={(value) => SetSource(value.value)}
                options={optionsSource}
                required
                {...source}
              />
            </div>
          </div>

          <div className={styles.flex}>
            <div className={styles.wrapper}>
              <label htmlFor="Tipo de Operação" className={styles.label}>
                Tipo de Operação
              </label>
              <CustomSelect
                name="operation_location"
                value={operation_location}
                placeholder={
                  operation_location ? operation_location : 'Selecione'
                }
                onChange={(value) => SetOperation_Location(value.value)}
                options={optionsOperationLocation}
                required
                {...operation_location}
              />
            </div>

            <div className={styles.wrapper}>
              <label htmlFor="description" className={styles.label}>
                Descrição
              </label>
              <textarea
                id="description"
                name="description"
                rows="1"
                maxlength="140"
                placeholder="Preencha com o motivo e detalhes da compra"
                className={styles.input}
                required
                {...description}
              />
            </div>
          </div>

          {loading ? (
            <Button disabled>Registrando...</Button>
          ) : (
            <Button onClick={() => status === true && setIsOpen(true)}>
              Registrar
            </Button>
          )}

          {error && <Error error={error} />}

          <Modal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            sucess={sucess}
            SetCenter={SetCenter}
            SetSource={SetSource}
            SetOperation_Location={SetOperation_Location}
            date={date}
          ></Modal>
        </form>
      </section>
    );
  }
};

export default RegistrarCusto;
