// export const API_URL = 'http://192.168.1.96:4000';
// export const API_URL = 'https://cft-dev.herokuapp.com';
export const API_URL = 'https://cft-pro.herokuapp.com';

// LOGIN
export function TOKEN_POST(body) {
  return {
    url: API_URL + '/user/login',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

// ESQUECEU A SENHA
export function FORGOT_PASSWORD(body) {
  return {
    url: API_URL + '/user/forgotPassword',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

// LISTAR CUSTOS
export function LISTAR_CUSTOS_GET({ offset, limit }) {
  return {
    url: `${API_URL}/cost/list?offset=${offset}&limit=${limit}`,
    options: {
      method: 'GET',
      cache: 'no-store',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
    },
  };
}

// REGISTRAR CUSTO
export function REGISTRAR_CUSTO_POST(body) {
  return {
    url: API_URL + '/cost/register',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    },
  };
}

// SELECIONAR CUSTO
export function SELECIONAR_CUSTO_GET(id) {
  return {
    url: `${API_URL}/cost/select/${id}`,
    options: {
      method: 'GET',
      headers: {
        Authorization: window.localStorage.getItem('token'),
      },
    },
  };
}

// EDITAR CUSTO
export function EDITAR_CUSTO_POST(body) {
  return {
    url: API_URL + '/cost/edit',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    },
  };
}

// DELETAR CUSTO
export function DELETAR_CUSTO_POST(id) {
  return {
    url: `${API_URL}/cost/remove/${id}`,
    options: {
      method: 'POST',
      headers: {
        Authorization: window.localStorage.getItem('token'),
      },
    },
  };
}

// REGISTRAR USUÁRIO
export function REGISTRAR_USUARIO_POST(body) {
  return {
    url: API_URL + '/user/register',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    },
  };
}
