import React from 'react';
import ReactDom from 'react-dom';
import styles from './Modal.module.css';
import Button from '../Forms/Button';
import Sucess from './Sucess';

export default function Modal({
  open,
  onClose,
  sucess,
  SetCenter,
  SetSource,
  SetOperation_Location,
  date,
}) {
  if (!open) return null;

  return ReactDom.createPortal(
    <>
      <div className={styles.overlay} />
      <div className={styles.modal}>
        {sucess && (
          <>
            <Sucess sucess={sucess} />
            <p>
              Deseja cadastrar outro custo com a mesma Data, Centro, Natureza e
              Tipo de Operação?
            </p>
            <div className={styles.flex}>
              <Button
                onClick={() => {
                  onClose();
                }}
              >
                Sim
              </Button>
              <div className={styles.invisible}></div>
              <Button
                onClick={() => {
                  SetCenter(null);
                  SetSource(null);
                  SetOperation_Location(null);
                  date.setValue('');
                  onClose();
                }}
              >
                Não
              </Button>
            </div>
          </>
        )}
      </div>
    </>,
    document.getElementById('portal'),
  );
}
