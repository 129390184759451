import React from 'react';
import { REGISTRAR_USUARIO_POST } from '../../Api';
import useFetch from '../../Hooks/useFetch';
import useForm from '../../Hooks/useForm';
import Button from '../Forms/Button';
import Head from '../Helper/Head';
import Error from '../Helper/Error';
import Sucess from '../Helper/Sucess';
import SistemaIndisponivel from '../SistemaIndisponivel';
import MaskedInput from 'react-text-mask';
import styles from './Requests.module.css';
import Input from '../Forms/Input';
import CustomSelect from '../Forms/CustomSelect';

const RegistrarUsuario = () => {
  const name = useForm();
  const email = useForm();
  const cpf_cnpj = useForm();
  const phone = useForm();
  const [sector, SetSector] = React.useState('');
  const [role, SetRole] = React.useState('');
  const password = useForm();
  const passwordConfirm = useForm();
  const [status, setStatus] = React.useState('');

  const { loading, error, setError, sucess, setSucess, request } = useFetch();

  async function handleSubmit(event) {
    event.preventDefault();

    if (password.value !== passwordConfirm.value) {
      setError(`Erro: As senhas não conferem!`);
    } else if (
      name.validate() &&
      email.validate() &&
      cpf_cnpj.validate() &&
      phone.validate() &&
      password.validate() &&
      passwordConfirm.validate()
    ) {
      const body = {
        name: name.value,
        email: email.value,
        cpf_cnpj: cpf_cnpj.value.replace(/\D/g, ''),
        phone: phone.value.replace(/\D/g, ''),
        sector: sector,
        role: role,
        password: password.value,
      };

      const { url, options } = REGISTRAR_USUARIO_POST(body);
      const { response, json } = await request(url, options);

      if (response === undefined) {
        setStatus(undefined);
        return null;
      }

      if (response !== undefined) {
        if (json === null) return null;

        if (response.ok === false) {
          let errors = JSON.stringify(json.errors)
            .replace(/[\\"]/g, '')
            .replace(/[\\{]/g, '')
            .replace(/[\\}]/g, '')
            .replace(/[[\]]/g, '')
            .replace(/[\\,]/g, ', ')
            .replace(/[\\:]/g, ': ');
          setError(`Erro: ${json.message}. Motivo(s): ${errors}.`);
        }
        if (response.ok === true) {
          setSucess(`Mensagem: ${json.message}`);
          name.setValue('');
          email.setValue('');
          cpf_cnpj.setValue('');
          phone.setValue('');
          password.setValue('');
          passwordConfirm.setValue('');
        }
      }
    }
  }

  const maskCpfCnpj = function (rawValue) {
    if (rawValue.length > 14) {
      return [
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ];
    } else {
      return [
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ];
    }
  };

  const maskPhone = function (rawValue) {
    return [
      '(',
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  };

  const optionsSector = [
    { value: 'diretoria', label: 'Diretoria' },
    { value: 'administrativo', label: 'Administrativo' },
    { value: 'acervo técnico', label: 'Acervo Técnico' },
    { value: 'assessorias', label: 'Assessorias' },
    { value: 'planejamento', label: 'Planejamento' },
    { value: 'sala técnica', label: 'Sala Técnica' },
    { value: 'tecnologia', label: 'Tecnologia' },
  ];

  const optionsRole = [
    { value: 'admin', label: 'Administrador' },
    { value: 'operator', label: 'Operador' },
    { value: 'viewer', label: 'Visualizador' },
  ];

  function onChangeSector(value) {
    SetSector(value.value);
  }

  function onChangeRole(value) {
    SetRole(value.value);
  }

  if (status === undefined) {
    return <SistemaIndisponivel />;
  } else {
    return (
      <section className="container animeLeft">
        <Head title="Registrar Usuário" />
        <h1 className="title">Registrar Usuário</h1>
        <form onSubmit={handleSubmit}>
          <div className={styles.flex}>
            <Input
              label="Nome do Usuário"
              placeholder="Nome e Sobrenome"
              type="text"
              name="name"
              required
              {...name}
            />

            <div className={styles.wrapper}>
              <label htmlFor="cpf_cnpj" className={styles.label}>
                CPF ou CNPJ
              </label>
              <MaskedInput
                mask={maskCpfCnpj}
                placeholder="000.000.000-00"
                className={styles.input}
                guide={false}
                id="cpf_cnpj"
                name="cpf_cnpj"
                onBlur={() => {}}
                onChange={() => {}}
                required
                {...cpf_cnpj}
              />
            </div>
          </div>

          <div className={styles.flex}>
            <Input
              label="Email"
              placeholder="nome@email.com"
              type="email"
              name="email"
              required
              {...email}
            />

            <div className={styles.wrapper}>
              <label htmlFor="phone" className={styles.label}>
                Telefone
              </label>
              <MaskedInput
                mask={maskPhone}
                placeholder="(85) 99999-9999"
                className={styles.input}
                guide={false}
                id="phone"
                name="phone"
                onBlur={() => {}}
                onChange={() => {}}
                required
                {...phone}
              />
            </div>
          </div>

          <div className={styles.flex}>
            <div className={styles.wrapper}>
              <label htmlFor="Setor Organizacional" className={styles.label}>
                Setor Organizacional
              </label>
              <CustomSelect
                name="sector"
                onChange={onChangeSector}
                options={optionsSector}
                {...sector}
              />
            </div>

            <div className={styles.wrapper}>
              <label htmlFor="Categoria do Usuário" className={styles.label}>
                Categoria do Usuário
              </label>
              <CustomSelect
                name="role"
                onChange={onChangeRole}
                options={optionsRole}
                {...role}
              />
            </div>
          </div>

          <div className={styles.flex}>
            <Input
              label="Senha"
              placeholder="Digite uma senha"
              type="password"
              name="password"
              required
              {...password}
            />

            <Input
              label="Confirmar Senha"
              placeholder="Confirme sua senha"
              type="password"
              name="passwordConfirm"
              required
              {...passwordConfirm}
            />
          </div>

          {loading ? (
            <Button disabled>Registrando...</Button>
          ) : (
            <Button>Registrar</Button>
          )}
          <Error error={error} />
          <Sucess sucess={sucess} />
        </form>
      </section>
    );
  }
};

export default RegistrarUsuario;
