import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { UserContext } from '../../UserContext';
import NotFound from '../NotFound';

const ProtectedRoute = (props) => {
  const { login } = React.useContext(UserContext);
  const role = localStorage.getItem('role');

  if (login === true) {
    switch (props.path) {
      case 'user/*':
        return <Route {...props} />;
      case 'cost/list':
        if (role === 'admin' || role === 'viewer') {
          return <Route {...props} />;
        } else {
          return <Route path="*" element={<NotFound />} />;
        }
      case 'cost/list/today':
        return <Route {...props} />;
      case 'cost/register':
        if (role === 'admin' || role === 'operator') {
          return <Route {...props} />;
        } else {
          return <Route path="*" element={<NotFound />} />;
        }
      case 'cost/edit':
        if (role === 'admin') {
          return <Route {...props} />;
        } else {
          return <Route path="*" element={<NotFound />} />;
        }
      case 'user/register':
        if (role === 'admin') {
          return <Route {...props} />;
        } else {
          return <Route path="*" element={<NotFound />} />;
        }
      default:
        return <Route path="*" element={<NotFound />} />;
    }
  } else if (login === false) return <Navigate to="/login" />;
  else return null;
};

export default ProtectedRoute;
