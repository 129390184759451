import React from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../UserContext';
import Button from './Forms/Button';
import styles from './Home.module.css';

const Home = () => {
  const { login } = React.useContext(UserContext);

  return (
    <section className={styles.home}>
      <div className={styles.info}>
        <h1 className="title">CF | Transitar</h1>
        <p>
          Realize registros e análises financeiras através da nossa aplicação
          web.
        </p>
        {login === true ? (
          <Link to="/user">
            <Button>Menu Admin</Button>
          </Link>
        ) : (
          <Link to="/login">
            <Button>Fazer Login</Button>
          </Link>
        )}
      </div>
    </section>
  );
};

export default Home;
