import React from 'react';
import Select from 'react-select';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    background: '#eee',
    borderBottom: 'none',
    color: '#333',
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    border: '1px solid #eee',
    display: 'flex',
    width: '23.5rem',
    height: '50px',
    fontSize: '1rem',
    fontFamily: 'Helvetica, Arial, sans-serif',
    color: '#333',
    padding: '0px 0.2rem 0px 0.2rem',
    background: '#eee',
    borderRadius: '0.4rem',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

function CustomSelect({ style, options, onChange, ...props }) {
  return (
    <div style={style}>
      <Select
        placeholder="Selecione"
        styles={customStyles}
        options={options}
        onChange={onChange}
        {...props}
      />
    </div>
  );
}

export default CustomSelect;
