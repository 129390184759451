import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './Components/Footer';
import Header from './Components/Header';
import Home from './Components/Home';
import Login from './Components/Login/Login';
import { UserStorage } from './UserContext';
import ProtectedRoute from './Components/Helper/ProtectedRoute';
import User from './Components/User/User';
import NotFound from './Components/NotFound';
import ListarCustos from './Components/Requests/ListarCustos';
import RegistrarCusto from './Components/Requests/RegistrarCusto';
import RegistrarUsuario from './Components/Requests/RegistrarUsuario';
import EditarCusto from './Components/Requests/EditarCusto';
import ListarCustosHoje from './Components/Requests/ListarCustosHoje';

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <UserStorage>
          <Header />
          <main className="AppBody">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="login/*" element={<Login />} />
              <ProtectedRoute path="user/*" element={<User />} />

              <ProtectedRoute path="cost/list" element={<ListarCustos />} />
              <ProtectedRoute
                path="cost/list/today"
                element={<ListarCustosHoje />}
              />
              <ProtectedRoute
                path="cost/register"
                element={<RegistrarCusto />}
              />
              <ProtectedRoute path="cost/edit" element={<EditarCusto />} />
              <ProtectedRoute
                path="user/register"
                element={<RegistrarUsuario />}
              />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
          <Footer />
        </UserStorage>
      </BrowserRouter>
    </div>
  );
};

export default App;
