import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Header.module.css';
import { ReactComponent as Logo } from '../Assets/logo.svg';
import { UserContext } from '../UserContext';

const Header = () => {
  const { userLogout } = React.useContext(UserContext);
  const nome = localStorage.getItem('nome');
  const location = useLocation();

  // Logout quando entra na home e login
  React.useEffect(() => {
    async function autoLogout() {
      if (location.pathname === '/' || location.pathname === '/login') {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('nome');
      }
    }
    autoLogout();
  }, [location]);

  return (
    <header className={styles.header}>
      <nav className={`${styles.nav} container`}>
        {nome ? (
          <Link
            className={styles.logo}
            to="/user"
            aria-label="Controle Financeiro | Transitar"
          >
            <Logo className={styles.transitar} />
            CF
          </Link>
        ) : (
          <Link
            className={styles.logo}
            to="/"
            aria-label="Controle Financeiro | Transitar"
          >
            <Logo className={styles.transitar} />
            CF
          </Link>
        )}

        {nome ? (
          <Link className={styles.login} to="/user">
            {nome}
          </Link>
        ) : (
          <Link className={styles.login} to="/login">
            Login
          </Link>
        )}
        {nome && <button onClick={userLogout}>Sair</button>}
      </nav>
    </header>
  );
};

export default Header;
