import React from 'react';
import { LISTAR_CUSTOS_GET } from '../../Api';
import styles from './Requests.module.css';
import useFetch from '../../Hooks/useFetch';
import Loading from '../Helper/Loading';
import MUIDataTable from 'mui-datatables';
import Head from '../Helper/Head';
import { TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';
import SistemaIndisponivel from '../SistemaIndisponivel';
import DeletarCusto from './DeletarCusto';

const ListarCustos = () => {
  const [results, setResults] = React.useState('');
  const [status, setStatus] = React.useState('');
  const { loading, request } = useFetch();
  const [role, setRole] = React.useState('');

  React.useEffect(() => {
    setRole(localStorage.getItem('role'));
  }, []);

  React.useEffect(() => {
    async function fetchAgentes() {
      const { url, options } = LISTAR_CUSTOS_GET({ offset: 0, limit: 100000 });
      const { response, json } = await request(url, options);
      if (response === undefined) {
        setStatus(undefined);
        return null;
      }
      if (response !== undefined) setResults(json.result);
    }
    fetchAgentes();
  }, [request]);

  function createData(
    date,
    center,
    source,
    operation_location,
    value,
    description,
    anoMes,
    id,
  ) {
    return {
      date,
      center,
      source,
      operation_location,
      value,
      description,
      anoMes,
      id,
    };
  }

  const data = [];
  for (var i = 0; i < results.length; i++) {
    const date = moment(results[i].date).format('L');
    const dateSplit = date.split('/');
    const anoMes = `${dateSplit[2]}/${dateSplit[0]}`;
    const diaMesAno = `${dateSplit[1]}/${dateSplit[0]}/${dateSplit[2]}`;
    const value = (results[i].value / 100).toFixed(2);
    const valor = `R$ ${value}`;

    data.push(
      createData(
        diaMesAno,
        results[i].center,
        results[i].source,
        results[i].operation_location,
        valor.replace('.', ','),
        results[i].description,
        anoMes,
        results[i]._id,
      ),
    );
  }

  const columns = [
    {
      name: 'date',
      label: 'Data de Pagamento',
      options: {
        sort: false,
        display: true,
      },
    },
    {
      name: 'center',
      label: 'Centro de Custo',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'source',
      label: 'Natureza da Operação',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'operation_location',
      label: 'Tipo de Operação',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'description',
      label: 'Descrição',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'value',
      label: 'Valor',
      options: {
        filter: true,
        sort: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = parseInt(obj1.data.replace('R$ ', ''), 10);
            let val2 = parseInt(obj2.data.replace('R$ ', ''), 10);
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        },
      },
    },
    {
      name: 'anoMes',
      label: 'Ano/Mês',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'id',
      label: 'Id do Registro',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'Deletar',
      options: {
        filter: false,
        sort: false,
        display: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(tableMeta.rowData[7]);
          if (role === 'admin')
            return <DeletarCusto id={tableMeta.rowData[7]} />;
          if (role !== 'admin') return null;
        },
      },
    },
  ];

  const options = {
    responsive: 'simple',
    draggableColumns: {
      enabled: true,
    },
    selectableRows: 'none',
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: 'false',
    download: 'true',
    onDownload: (buildHead, buildBody, columns, data) => {
      return '\uFEFF' + buildHead(columns) + buildBody(data);
    },
    downloadOptions: {
      filename: 'ListarCustos.csv',
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: false,
      },
    },
    textLabels: {
      body: {
        noMatch: 'Desculpe, nenhum registro foi encontrado',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Próxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'of', // 1-10 of 30
      },
      toolbar: {
        search: 'Pesquisar',
        viewColumns: 'Selecionar Colunas',
        filterTable: 'Filtrar Tabela',
      },
      filter: {
        title: 'FILTROS',
        reset: 'limpar',
        all: 'Todos',
      },
      viewColumns: {
        title: 'Mostrar Colunas',
        titleAria: 'Mostrar/Esconder Colunas da Tabela',
      },
    },
    customToolbarSelect: () => {},
    expandableRows: true,
    expandableRowsOnClick: false,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <>
          <TableRow className={styles.linha}>
            <TableCell colSpan={1}></TableCell>
            <TableCell colSpan={1}>
              <b>Id do Registro</b>
              <br /> {rowData[7]}
            </TableCell>
            <TableCell colSpan={7}></TableCell>
          </TableRow>
        </>
      );
    },
  };

  if (loading) return <Loading />;
  if (status === undefined) {
    return <SistemaIndisponivel />;
  } else {
    return (
      <section className="animeLeft">
        <Head title="Listar Todos os Custos" />
        <MUIDataTable
          title={'Listar Todos os Custos'}
          data={data.reverse()}
          columns={columns}
          options={options}
        />
      </section>
    );
  }
};

export default ListarCustos;
